<template>
  <div>
    <Pane />

    <div class="container">
      <a-descriptions bordered :column="2">
        <a-descriptions-item label="档案编号">
          {{ detail.code }}
        </a-descriptions-item>
        <a-descriptions-item label="档案名称">
          {{ detail.name }}
        </a-descriptions-item>
        <a-descriptions-item label="档案类型">
          {{ typeText }}
        </a-descriptions-item>
        <a-descriptions-item label="保密级别">
          <DataDictFinder dictType="secretLevel" :dictValue="detail.level" />
        </a-descriptions-item>
        <a-descriptions-item label="页数">
          {{ detail.pageNumber }}
        </a-descriptions-item>
        <a-descriptions-item label="档案位置">
          {{ detail.location }}
        </a-descriptions-item>
        <a-descriptions-item label="移交人">
          {{ detail.transfereeName }}
        </a-descriptions-item>
        <a-descriptions-item label="移交部门">
          {{ detail.transfereeDeptName }}
        </a-descriptions-item>
        <a-descriptions-item label="移交日期">
          {{ detail.createAt }}
        </a-descriptions-item>
        <a-descriptions-item label="保管期限">
          {{ detail.duration ? detail.duration + "月" : "长期" }}
        </a-descriptions-item>
        <a-descriptions-item label="关键词" :span="2">
          {{ detail.keyWords }}
        </a-descriptions-item>
        <a-descriptions-item label="档案说明" :span="2">
          {{ detail.remarks }}
        </a-descriptions-item>
        <a-descriptions-item
          label="附件"
          :span="2"
          v-if="Array.isArray(detail.fileList)"
        >
          <a
            v-for="item in detail.fileList"
            :key="item.id"
            :href="item.completePath"
            download
            target="_blank"
            >{{ item.name }}</a
          >
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { flatList } from "@/utils/flat";
export default {
  data() {
    return {
      detail: {},
    };
  },

  mounted() {
    const rawStr = window.localStorage.getItem("archive-detail");
    if (rawStr) {
      this.detail = JSON.parse(rawStr);
    }
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("arcType");
    },

    flatTypeList() {
      let result = [];
      flatList(this.typeList, result);
      return result;
    },

    typeText() {
      if (typeof this.detail.type === "string") {
        const arr = this.detail.type.split(",");
        return arr
          .map((item) => {
            const obj = this.flatTypeList.find(
              (element) => element.value === item
            );
            return obj ? obj.name : "";
          })
          .join(" - ");
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
